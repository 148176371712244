import { Box } from "@mui/material";

import { PullToRefreshLoader } from "./PullToRefreshLoader";
import { usePullToRefresh } from "./usePullToRefresh";

function applySpringTension(distance: number): number {
  const maxLength = 240;
  const elasticCoefficient = 0.6;
  return maxLength * (1 - Math.exp((-elasticCoefficient * distance) / maxLength));
}

interface PullToRefreshProps {
  onRefresh: () => Promise<any>;
}

export function PullToRefresh(props: PullToRefreshProps) {
  const { onRefresh } = props;

  const { dragDistance, isRefreshing, isPulling, percentage } = usePullToRefresh(onRefresh);
  const height = isRefreshing ? 56 : applySpringTension(dragDistance);

  return (
    <Box
      sx={{
        width: "100%",
        height: Math.max(height, 0),
        backgroundColor: (theme) => theme.palette.background.paper,
        transition: isPulling ? undefined : "all 0.2s",
      }}
    >
      <PullToRefreshLoader percentage={percentage} isLoading={isRefreshing} />
    </Box>
  );
}

import { Box, CircularProgress } from "@mui/material";

interface PullToRefreshLoaderProps {
  percentage: number;
  isLoading: boolean;
}

export function PullToRefreshLoader(props: PullToRefreshLoaderProps) {
  const { percentage, isLoading } = props;
  const loaderMargin = 16;

  return (
    <Box position="relative">
      <Box
        sx={{
          width: "100%",
          position: "absolute",
          opacity: isLoading ? 1 : 2 * percentage,
          top: isLoading ? loaderMargin : loaderMargin * percentage,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress
          size={24}
          thickness={6}
          variant={isLoading ? undefined : "determinate"}
          value={percentage * 100}
          sx={{
            "& circle": {
              transition: "none",
              strokeLinecap: "round",
            },
          }}
        />
      </Box>
    </Box>
  );
}

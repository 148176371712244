import { z } from "zod";

const CURRENCY_CODES = ["USD"] as const;

export const MoneySchema = z.object({
  amountInMinorUnits: z.number(),
  currencyCode: z.enum(CURRENCY_CODES),
});

// Ref. https://www.notion.so/0017-Dinero-js-v2-Monetary-Values-2776ba6d01c641aeb7d374664d8ea994
export type Money = z.infer<typeof MoneySchema>;
export type CurrencyCode = Money["currencyCode"];

import { mergeSxProps } from "@clipboard-health/ui-react";
import { Stack, type StackProps } from "@mui/material";
import { forwardRef } from "react";

export interface OlProps extends Omit<StackProps<"ol">, "component"> {}

export const Ol = forwardRef<HTMLOListElement, OlProps>((props, ref) => {
  const { sx } = props;
  return (
    <Stack
      {...props}
      ref={ref}
      sx={mergeSxProps({ paddingLeft: 2 }, sx)}
      spacing={1}
      component="ol"
    />
  );
});

Ol.displayName = "Ol";

import { LinearProgress } from "@mui/material";
import { type ReactElement } from "react";

interface ProgressBarProps {
  value: number; // Between 0 - 100
}

export function ProgressBar(props: ProgressBarProps): ReactElement {
  const { value } = props;
  return <LinearProgress variant="determinate" value={Math.min(value, 100)} />;
}

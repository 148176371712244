import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { IconButton, Tooltip } from "@mui/material";

interface InfoTooltipProps {
  infoText: string;
  fontSize?: "inherit" | "small" | "medium" | "large";
  onClick?: () => void;
}

export function InfoTooltip(props: InfoTooltipProps) {
  const { infoText, fontSize = "inherit", onClick } = props;
  return (
    /**
     * The default touch delay of 700ms,
     * results in showing the tooltip only in case of long press for mobile devices.
     * This is not the desired behavior and we want the tooltip to show as soon as it is touched.
     * Below solution mimics the code at, https://github.com/mui/material-ui/issues/14948#issuecomment-844043824
     */
    <Tooltip arrow title={infoText} leaveTouchDelay={10_000} enterTouchDelay={50} onClick={onClick}>
      <IconButton size="small">
        <InfoOutlinedIcon fontSize={fontSize} />
      </IconButton>
    </Tooltip>
  );
}

import { toError } from "@clipboard-health/util-ts";

import { isAndroidPlatform, isIosPlatform } from "../utils";

declare const UXCam: {
  setUserIdentity: (userIdentity: string) => void;
  stopSessionAndUploadData: () => void;
  setUserProperty: (key: string, value: string) => void;
  setUserProperties: (properties: Record<string, unknown>) => void;
  startWithKey: (key: string) => void;
  tagScreenName: (screenName: string) => void;
  optIntoSchematicRecordings: () => void;
  startWithConfiguration: (config: Record<string, unknown>) => void;
};

function isUXCamAvailable() {
  try {
    // UXCam is defined as a global variable by cordova
    return (isIosPlatform() || isAndroidPlatform()) && UXCam;
  } catch (error) {
    // UXCam variable is not available in playwright e2e test enviornment.
    // So this catch is needed to fix playwright e2e tests
    if (toError(error).name === "ReferenceError") {
      return false;
    }

    throw error;
  }
}

const setUserProperty = (key: string, value: string) => {
  if (isUXCamAvailable()) {
    UXCam.setUserProperty(key, value);
  }
};

export const UXCamSdk = {
  setUserIdentity: (userIdentity: string) => {
    if (isUXCamAvailable()) {
      UXCam.setUserIdentity(userIdentity);
    }
  },
  stopSessionAndUploadData: () => {
    if (isUXCamAvailable()) {
      UXCam.stopSessionAndUploadData();
    }
  },
  setUserProperty,
  setUserProperties: (properties: Record<string, unknown>) => {
    for (const [key, value] of Object.entries(properties)) {
      if (
        typeof value === "string" &&
        value.length > 0 &&
        typeof key === "string" &&
        key.length > 0
      ) {
        setUserProperty(key, value);
      }
    }
  },
  startWithKey: (key: string) => {
    if (isUXCamAvailable()) {
      UXCam.startWithKey(key);
    }
  },
  tagScreenName: (screenName: string) => {
    if (isUXCamAvailable()) {
      UXCam.tagScreenName(screenName);
    }
  },
  optIntoSchematicRecordings: () => {
    if (isUXCamAvailable()) {
      UXCam.optIntoSchematicRecordings();
    }
  },
  startWithConfiguration: (config: Record<string, unknown>) => {
    if (isUXCamAvailable()) {
      UXCam.startWithConfiguration(config);
    }
  },
} as const;

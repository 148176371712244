import { type SnackbarMessage, useSnackbar } from "notistack";
import { useCallback } from "react";

/**
 * Helper utility to show standard toasts
 */
export function useToast() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const showSuccessToast = useCallback(
    (message: SnackbarMessage) => {
      enqueueSnackbar({
        message,
        variant: "success",
      });
    },
    [enqueueSnackbar]
  );

  const showInfoToast = useCallback(
    (message: SnackbarMessage) => {
      enqueueSnackbar({
        message,
        variant: "info",
      });
    },
    [enqueueSnackbar]
  );

  const showErrorToast = useCallback(
    (message: SnackbarMessage) => {
      enqueueSnackbar({
        message,
        variant: "error",
      });
    },
    [enqueueSnackbar]
  );

  const showWarningToast = useCallback(
    (message: SnackbarMessage) => {
      enqueueSnackbar({
        message,
        variant: "warning",
      });
    },
    [enqueueSnackbar]
  );

  return {
    showSuccessToast,
    showInfoToast,
    showErrorToast,
    showWarningToast,
    closeSnackbar,
  };
}

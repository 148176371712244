import {
  Autocomplete as MuiAutocomplete,
  type AutocompleteProps as MuiAutocompleteProps,
  Box,
  createFilterOptions,
  Stack,
  TextField,
} from "@mui/material";
import { type ReactNode } from "react";

import { PRIMARY_HIGHLIGHT_COLORS, SECONDARY_HIGHLIGHT_COLORS } from "../Theme/constants";

export type OptionItemVariant = "primary" | "secondary" | "default";

export interface AutocompleteOptionItem {
  id: string;
  leftItem: ReactNode;
  label: string;
  rightItem?: ReactNode;
  backgroundMode?: OptionItemVariant;
}
export interface LeftRightItemsAutocompleteProps
  extends Omit<
    MuiAutocompleteProps<AutocompleteOptionItem, false, true, false>,
    "renderInput" | "renderOption" | "isOptionEqualToValue" | "filterOptions"
  > {
  limit?: number;
}

export const backgroundColorVariants: Record<OptionItemVariant, string> = {
  primary: PRIMARY_HIGHLIGHT_COLORS.backgroundColor,
  secondary: SECONDARY_HIGHLIGHT_COLORS.backgroundColor,
  default: "inherit",
};

/*
This component will be used to show autocomplete list with left and right items,
It may not fit similar use cases that require a different layout.
Consider refactoring the renderOption component instead.
*/
export function LeftRightItemsAutocomplete(props: LeftRightItemsAutocompleteProps) {
  const { limit = 100 } = props;
  return (
    <MuiAutocomplete
      {...props}
      disablePortal
      isOptionEqualToValue={(option, value) => option.id === value.id}
      filterOptions={createFilterOptions({ limit })}
      renderOption={(optionProps, option) => {
        const { leftItem = "", rightItem, id } = option;
        return (
          <Box {...optionProps} key={id} component="li">
            <Stack flexDirection="row" justifyContent="space-between" width="100%" gap={2}>
              {leftItem}
              {rightItem}
            </Stack>
          </Box>
        );
      }}
      renderInput={(params) => {
        return <TextField {...params} variant="standard" />;
      }}
    />
  );
}

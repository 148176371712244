import { BaseLink, ExternalLink } from "@clipboard-health/ui-react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, Card, CardActionArea, Stack } from "@mui/material";
import type { ReactNode } from "react";
import { type LinkProps as RouterLinkProps } from "react-router-dom";

import { PRIMARY_HIGHLIGHT_COLORS } from "../../Theme/constants";
import { isLinkExternal } from "../../utils/isLinkExternal";

export interface LinkCardProps {
  to: RouterLinkProps["to"];
  title: ReactNode;
  subtitle: ReactNode;
  variant: "info" | "warning" | "promotion";
  leadingIcon?: ReactNode;
  isFullHeight?: boolean;
  onClick?: () => void;
}

export function LinkCard(props: LinkCardProps) {
  const { to, title, subtitle, variant, leadingIcon, isFullHeight, onClick } = props;

  return (
    <Card
      sx={{
        border: (theme) =>
          `1px solid ${
            variant === "promotion" ? PRIMARY_HIGHLIGHT_COLORS.color : theme.palette[variant].main
          }`,
        borderRadius: 1,
        backgroundColor: (theme) =>
          variant === "promotion"
            ? PRIMARY_HIGHLIGHT_COLORS.backgroundColor
            : theme.palette[variant].light,
        height: isFullHeight ? "100%" : "auto",
      }}
    >
      <CardActionArea
        to={to}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          pl: leadingIcon ? 1 : 2,
          pr: leadingIcon ? 0 : 1,
          paddingY: 1,
        }}
        component={isLinkExternal(to) ? ExternalLink : BaseLink}
        onClick={onClick}
      >
        <Stack direction="row" alignItems="center">
          {leadingIcon}
          <Box>
            {title}
            {subtitle}
          </Box>
        </Stack>

        <KeyboardArrowRightIcon
          sx={{
            fontSize: "1.5rem",
          }}
        />
      </CardActionArea>
    </Card>
  );
}

import { ThemeProvider } from "@mui/material/styles";
import { type ReactElement, type ReactNode } from "react";

import { getTheme } from "./theme";

const theme = getTheme("light");

interface Props {
  children: ReactNode;
}
export function MuiThemeProvider(props: Props): ReactElement {
  const { children } = props;

  /**
   * TODO - add CssBaseline inside the ThemeProvider, and remove `normalize.css`
   * See: https://mui.com/material-ui/getting-started/usage/#cssbaseline
   */
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

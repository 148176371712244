import { mergeSxProps } from "@clipboard-health/ui-react";
import { Box, type BoxProps } from "@mui/material";

interface LiProps extends Omit<BoxProps<"li">, "sx" | "component"> {
  sx?: Omit<BoxProps["sx"], "display">;
}

export function Li(props: LiProps): JSX.Element {
  const { sx, ...rest } = props;
  return <Box component="li" {...rest} sx={mergeSxProps(sx, { display: "list-item" })} />;
}
